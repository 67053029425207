<template>
  <div class="uploadBtn_block uploadBtn_mini">
    <Loader v-if="loading"/>
    <div v-else>
        <UploadButtonAcceptState
        v-if="fileInfo.name && !fileInfo.isLarge"
        :name="fileInfo.name"
        :label="!disableLabel && label"
        :sizeMb="fileInfo.size"
        @delete="onDelete"/>
        <UploadButtonErrorState
        v-if="error"
        noIcon
        :message="errorMessage"/>
        <UploadButtonUploadState
        v-if="!fileInfo.name || error"
        ref="input"
        :label="label"
        :disableLabel="disableLabel"
        @change="onChange"/>
      </div>
  </div>
</template>

<script>
import { Loader } from 'qic-components'
import UploadButtonErrorState from '@/components/uploadButtonStates/UploadButtonErrorState'
import UploadButtonUploadState from '@/components/uploadButtonStates/UploadButtonUploadState'
import UploadButtonAcceptState from '@/components/uploadButtonStates/UploadButtonAcceptState'
export default {
  name: 'BaseDocButton',
  props: {
    file: {},
    code: {},
    id: {},
    infoFromServer: { type: Object, default: () => ({}) },
    maxFileSize: { type: Number, default: 20 },
    label: {},
    // istimaraUrl: { type: String },
    disableLabel: { type: Boolean, default: false },
    deleteCallback: { type: Function, default: () => new Promise((resolve, reject) => { setTimeout(() => { resolve(1) }, 1000) }) },
    uploadCallback: { type: Function, default: () => new Promise((resolve, reject) => { setTimeout(() => { resolve(1) }, 1000) }) }
  },
  components: {
    Loader,
    UploadButtonErrorState,
    UploadButtonUploadState,
    UploadButtonAcceptState
  },
  emits: ['change', 'delete'],
  data () {
    return {
      fileInfo: {
        name: null,
        size: null,
        isLarge: false
      },
      loading: false,
      error: false
    }
  },
  computed: {
    id_ () {
      return this.id
    },
    // istimara () {
    //   if (!this.istimaraUrl) return ''
    //   return require(`@/assets/images/docs/${this.istimaraUrl}`)
    // },
    errorMessage () {
      switch (this.error) {
        case 'change':
          return this.$t('erro-upload-docs')
        case 'large':
          return this.$t('claim_file_limit_aware', { size: this.maxFileSize })
        case 'delete':
          return this.$t('erro-upload-docs')
        default:
          return ''
      }
    }
  },
  methods: {
    onChange () {
      this.error = ''
      const input = this.$refs.input.$refs.input
      if (!input.files || !input.files[0]) {
        this.nullFileInfo()
        return
      }
      const file = input.files[0]
      if (this.isFileLarge(file)) {
        this.error = 'large'
        this.changeFileInfo(file)
        return
      }
      this.loading = true
      this.uploadCallback(this.code, file)
        .then((docId) => {
          if (!docId) {
            this.error = 'change'
            return
          }
          this.changeFileInfo(file)
          this.$emit('change', { file, docId })
        })
        .catch(() => {
          this.error = 'change'
        })
        .finally(() => {
          this.loading = false
        })
    },
    onDelete () {
      this.error = ''
      this.loading = true
      this.deleteCallback(this.id_)
        .then(() => {
          this.$emit('change', { file: null, docId: null })
          this.clear()
        })
        .catch(() => {
          this.error = 'delete'
        })
        .finally(() => {
          this.loading = false
        })
    },
    clear () {
      this.nullFileInfo()
    },
    isFileLarge (file) {
      return this.getFileSize(file) > this.maxFileSize
    },
    getFileSize (file) {
      return (file.size / 1000000).toFixed(2)
    },
    changeFileInfo (file) {
      this.fileInfo.size = this.getFileSize(file)
      this.fileInfo.name = file.name
      this.fileInfo.isLarge = this.isFileLarge(file)
    },
    nullFileInfo () {
      this.fileInfo = {
        name: null,
        size: null,
        isLarge: false
      }
    }
  },
  mounted () {
    if (!this.file) {
      if (this.infoFromServer?.name) {
        this.fileInfo.name = this.infoFromServer.name
        this.fileInfo.size = this.infoFromServer?.size
        this.fileInfo.isLarge = false
      }
      return
    }
    const dt = new DataTransfer() || new ClipboardEvent('').clipboardData
    dt.items.add(this.file)
    const fileList = dt.files
    const input = this.$refs.input.$refs.input
    input.files = fileList
    this.changeFileInfo(this.file)
  }
}
</script>

<style lang="scss">
.upload-popup__item.uploadBtn.uploadBtn__grid {
  grid-template-columns: auto 1fr 24px;
}
.upload-popup__item.uploadBtn.uploadBtn__grid.upload-popup__item_first {
  grid-template-columns: 1fr 24px;
}
.uploadBtn_no-icon{
  margin-top: 16px;
}
</style>
